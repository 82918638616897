import React, { useState } from "react";
import { Spin, Steps, notification } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { encryptData, UseAxios } from "../../utils";
import Step1Form from "./step-1-form";
import Step2Form from "./step-2-form";
import Step3Form from "./step-3-form";
import "./style.scss";

const defaultFormValues = {
  basic_details: {
    last_name: "",
    marital_status: "Unmarried",
  },
  horoscope_details: {
    padham: 0,
    lagnam: "",
    is_sevvai: "",
    is_ragu_kedhu: "",
  },
  education_details: {
    college: "",
    education_details: "",
    job_title: "",
    company_name: "",
    currently_employed_country: "India",
  },
  contact_details: {
    country: "India",
    contact_number_2: "",
  },
  physical_details: {
    blood_group: "",
  },
  family_details: {
    father_occupation: "",
    mother_occupation: "",
    no_of_sisters: 0,
    no_sisters_married: 0,
    no_of_brothers: 0,
    no_brothers_married: 0,
    family_income_in_rs: "",
    about_family: "",
    is_own_house: "no",
    total_asset_value: "",
    asset_details: "",
  },
  partner_preference: {
    age_range: [18, 45],
    marital_status: "Unmarried",
    skin_tone: "",
    is_horoscope_match: "",
    is_sevvai: "",
    is_ragu_kethu: "",
    is_brother: "",
    is_sisters: "",
    food_preference: "",
    description: "",
    education: [],
    occupation: "",
  },
};

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultFormValues);
  const [registerStep, setRegisterStep] = useState(0);
  const [verifiedProfile, setVerifiedProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleRegistration = (payload) => {
    const url = "user_registration";
    UseAxios.post(url, payload)
      .then((response) => {
        notification.success({ message: response.data.message });
        navigate("/login");
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(false));
  };

  const handleDataUpdate = (data) => {
    const allData = { ...formData, ...data };
    const clonedFormData = JSON.parse(JSON.stringify(allData));
    setFormData({
      ...formData,
      ...data,
    });

    const { basic_details, horoscope_details, contact_details } =
      clonedFormData;
    const { password, confirm_password, dob } = basic_details;
    const { time_of_birth } = horoscope_details;
    const { contact_number_2 } = contact_details;

    // Updated basic_details form data
    basic_details.password = encryptData(password);
    basic_details.confirm_password = encryptData(confirm_password);
    basic_details.dob = dayjs(dob).format("YYYY/MM/DD");
    basic_details.country_code = "+91";

    // Updated horoscope_details form data
    horoscope_details.time_of_birth = dayjs(time_of_birth).format("HH:mm:00");

    // Updated contact_details form data
    contact_details.contact_number_2 =
      contact_number_2 === null ? "" : contact_number_2;
    handleRegistration(clonedFormData);
  };

  const handleFormData = (data, step) => {
    setFormData({
      ...formData,
      ...data,
    });
    if (step === 3) {
      handleDataUpdate(data);
    } else {
      if (step === 0) {
        setVerifiedProfile(formData.basic_details.email_id);
      }
      setIsLoading(false);
      setRegisterStep(step);
    }
  };

  const registerItems = [
    {
      title: "Basic Details",
    },
    {
      title: (
        <>
          Horoscope & Contact Details <br />
          Education & Occupation Details
        </>
      ),
    },
    {
      title: (
        <>
          Physical & Family Details
          <br />
          Partner Preference
        </>
      ),
    },
  ];

  return (
    <div className="register-container">
      <Spin spinning={isLoading} size="large" tip="Loading...">
        <Steps
          size="small"
          type="navigation"
          current={registerStep}
          items={registerItems}
        />
        <br />
        {registerStep === 0 && (
          <Step1Form
            formData={formData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleFormData={handleFormData}
            verifiedProfile={verifiedProfile}
          />
        )}
        {registerStep === 1 && (
          <Step2Form
            formData={formData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleFormData={handleFormData}
          />
        )}
        {registerStep === 2 && (
          <Step3Form
            formData={formData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleFormData={handleFormData}
          />
        )}
      </Spin>
    </div>
  );
};
export default Register;
