import React from "react";
import { Button, Table } from "antd";
import { EyeFilled } from "@ant-design/icons";

const ViewDetails = (properties) => {
  const { cardDetails } = properties;

  const profileKeys = {
    1: { id: "profile_id", name: "profile_name" },
    2: {
      id: "visited_profile_id",
      name: "visited_profile_name",
    },
    3: {
      id: "requested_profile_id",
      name: "requested_profile_name",
    },
  };

  const createColumns = (key) => [
    {
      align: "center",
      dataIndex: profileKeys[key].id,
      title: "Profile ID",
    },
    {
      align: "center",
      dataIndex: profileKeys[key].name,
      title: "Name",
    },
    {
      align: "center",
      title: "Action",
      render: (_, record) => (
        <Button
          type="link"
          href={`/search/${record[profileKeys[key].id]}`}
          target="_blank"
          rel="noreferrer noopener"
          icon={<EyeFilled />}
        >
          View
        </Button>
      ),
    },
  ];

  const columnsConfig = {
    get_profiles_shortlisted_by_me: createColumns(1),
    get_profiles_shortlisted_my_profile: createColumns(1),
    get_profiles_visited_by_me: createColumns(2),
    get_visited_profiles_details: createColumns(2),
    get_pending_connection_request_profiles: createColumns(3),
    get_accepted_connection_request_profiles: createColumns(3),
    get_received_connection_request_profiles: createColumns(3),
    get_contact_detail_viewed_profiles: createColumns(1),
  };

  return (
    <Table
      bordered
      columns={columnsConfig[cardDetails.selectedCard.key]}
      dataSource={cardDetails.fetchedData}
      rowKey={crypto.randomUUID()}
      pagination={{
        position: ["bottomCenter"],
        pageSize: 10,
      }}
    />
  );
};

export default ViewDetails;
