import React, { useEffect, useState } from "react";
import { Button, Flex, Form, Input, notification, Row, Col, Image } from "antd";
import { useLocation } from "react-router-dom";
import { UseAuthAxios } from "../../utils";
import ReportImage from "../../image/report.jpg";

const { TextArea } = Input;

const Report = () => {
  const location = useLocation();
  const authAxios = UseAuthAxios();
  const [form] = Form.useForm();
  const [reportType, setreporttype] = useState("");

  const onFinish = (formValues) => {
    report(formValues?.ReportedIssue);
  };

  const report = (payload) => {
    let url = "";
    if (reportType === "issue") {
      url = "report_issue";
    } else {
      url = "report_account";
      payload = {
        reported_account: 2,
        report: payload.reported_issue,
      };
    }

    authAxios
      .post(url, payload)
      .then((response) => {
        notification.success({
          message: response.data.message,
        });
        form.resetFields();
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    if (location.pathname === "/reportissue") {
      setreporttype("issue");
    } else {
      setreporttype("account");
    }
  }, [location]);

  return (
    <Row align="middle" justify="space-evenly" className="login-container">
      <Col xs={0} sm={0} md={0} lg={10} xl={10} xxl={10}>
        <Image
          src={ReportImage}
          preview={false}
          alt="login-img"
          className="h-100"
        />
      </Col>
      <Col
        xs={24}
        sm={20}
        md={18}
        lg={14}
        xl={14}
        xxl={14}
        className="form-container"
      >
        <Form
          form={form}
          size="large"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Report what happened below we are truly sorry!"
            name={["ReportedIssue", "reported_issue"]}
          >
            <TextArea
              rows={5}
              count={{
                show: true,
              }}
              maxLength={250}
              placeholder="Let us know what's going on"
            />
          </Form.Item>

          <Flex justify="end" gap="small">
            <Button type="primary" htmlType="submit" className="w-25  my-3">
              Submit
            </Button>
          </Flex>
        </Form>
      </Col>
    </Row>
  );
};

export default Report;
