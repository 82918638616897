import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Slider,
  Typography,
} from "antd";
import CHOICES from "../../constant/register-choices.json";

const { Title } = Typography;
const { Group } = Radio;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

const {
  SKIN_COLOR_CHOICES,
  BODY_TYPE_CHOICES,
  DIET_CHOICES,
  BLOOD_GROUP,
  HEIGHT_CHOICES,
  WEIGHT_CHOICES,
  SIBLING_CHOICES,
  FAMILY_STATUS_CHOICES,
  FAMILY_VALUE_CHOICES,
  FAMILY_TYPE_CHOICES,
  ASSET_VALUE_CHOICES,
  MARITAL_STATUS_CHOICES,
  EDUCATION_LIST,
  OCCUPATION_CATEGORY,
  OCCUPATION_LIST,
  PREFERENCE_CHOICES,
  MONTHLY_INCOME_CHOICES,
} = CHOICES;

const Step3Form = (props) => {
  const { formData, handleFormData, isLoading, setIsLoading } = props;

  const form = Form.useForm()[0];
  const [sisterCount, setSisterCount] = useState(0);
  const [brotherCount, setBrotherCount] = useState(0);

  const handlePrevious = () => {
    const fieldsValue = form.getFieldsValue();
    handleFormData(fieldsValue, 1);
  };

  const handleSisterCount = (count) => {
    setSisterCount(count);
    form.setFieldsValue({
      family_details: {
        no_sisters_married: 0,
      },
    });
  };

  const handleBrotherCount = (count) => {
    setBrotherCount(count);
    form.setFieldsValue({
      family_details: {
        no_brothers_married: 0,
      },
    });
  };

  const onFinish = (formValues) => {
    setIsLoading(true);
    handleFormData(formValues, 3);
  };

  useEffect(() => {
    const {
      family_details: { no_of_sisters, no_of_brothers },
    } = form.getFieldsValue();
    setSisterCount(no_of_sisters);
    setBrotherCount(no_of_brothers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      labelWrap
      form={form}
      autoComplete="off"
      scrollToFirstError
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      initialValues={formData}
    >
      <Title level={4}>Physical Details</Title>
      <>
        <Form.Item
          label="Skin Tone"
          name={["physical_details", "skin_tone"]}
          rules={[
            {
              required: true,
              message: "Please choose your skin tone",
            },
          ]}
        >
          <Group>
            {SKIN_COLOR_CHOICES?.map(({ value, label }, index) => (
              <Radio key={value} value={value} autoFocus={index === 0}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Body Type"
          wrapperCol={{ span: 24 }}
          name={["physical_details", "body_type"]}
          rules={[
            {
              required: true,
              message: "Please choose your body type",
            },
          ]}
        >
          <Group>
            {BODY_TYPE_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Diet Type"
          name={["physical_details", "diet"]}
          rules={[
            {
              required: true,
              message: "Please choose your diet",
            },
          ]}
        >
          <Group>
            {DIET_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Blood Group"
          name={["physical_details", "blood_group"]}
        >
          <Select placeholder="Please select your blood group">
            {BLOOD_GROUP?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Height"
          name={["physical_details", "height_in_cm"]}
          rules={[{ required: true, message: "Please enter your height" }]}
        >
          <Select showSearch placeholder="Select your height">
            {HEIGHT_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Weight"
          name={["physical_details", "weight_in_kg"]}
          rules={[{ required: true, message: "Please select your weight" }]}
        >
          <Select showSearch placeholder="Select your weight">
            {WEIGHT_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
      <Title level={4}>Family Details</Title>
      <>
        <Form.Item
          label="Father Name"
          name={["family_details", "father_name"]}
          rules={[
            {
              required: true,
              message: "Please enter your father name",
            },
          ]}
        >
          <Input placeholder="Enter your father name" />
        </Form.Item>
        <Form.Item
          label="Father Occupation"
          name={["family_details", "father_occupation"]}
        >
          <Input placeholder="Enter your father occupation" />
        </Form.Item>
        <Form.Item
          label="Mother Name"
          name={["family_details", "mother_name"]}
          rules={[
            {
              required: true,
              message: "Please enter your mother name",
            },
          ]}
        >
          <Input placeholder="Enter your mother name" />
        </Form.Item>
        <Form.Item
          label="Mother Occupation"
          name={["family_details", "mother_occupation"]}
        >
          <Input placeholder="Enter your mother occupation" />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="No of Sisters"
              labelCol={{ sm: 8, md: 12 }}
              name={["family_details", "no_of_sisters"]}
            >
              <Select onChange={handleSisterCount}>
                {SIBLING_CHOICES?.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              labelCol={{ sm: 8, md: 12 }}
              label="No of married sisters"
              name={["family_details", "no_sisters_married"]}
            >
              <Select disabled={!sisterCount}>
                {SIBLING_CHOICES.slice(0, sisterCount + 1).map(
                  ({ value, label }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              labelCol={{ sm: 8, md: 12 }}
              label="No of Brothers"
              name={["family_details", "no_of_brothers"]}
            >
              <Select onChange={handleBrotherCount}>
                {SIBLING_CHOICES?.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              labelCol={{ sm: 8, md: 12 }}
              label="No of married brothers"
              name={["family_details", "no_brothers_married"]}
            >
              <Select disabled={!brotherCount}>
                {SIBLING_CHOICES.slice(0, brotherCount + 1).map(
                  ({ value, label }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Family Status"
          name={["family_details", "family_status"]}
          rules={[
            {
              required: true,
              message: "Please choose your family status",
            },
          ]}
        >
          <Group>
            {FAMILY_STATUS_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Family Value"
          name={["family_details", "family_value"]}
          rules={[
            {
              required: true,
              message: "Please choose your family value",
            },
          ]}
        >
          <Group>
            {FAMILY_VALUE_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Family Type"
          name={["family_details", "family_type"]}
          rules={[
            {
              required: true,
              message: "Please choose your family type",
            },
          ]}
        >
          <Group>
            {FAMILY_TYPE_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Family Income (Per Month)"
          name={["family_details", "family_income_in_rs"]}
        >
          <Select showSearch placeholder="Select your monthly family income">
            {MONTHLY_INCOME_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="About Family"
          name={["family_details", "about_family"]}
        >
          <TextArea
            maxLength={250}
            count={{
              show: true,
            }}
            rows={3}
            placeholder="Describe about your family"
          />
        </Form.Item>
        <Form.Item label="Own House" name={["family_details", "is_own_house"]}>
          <Group>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Group>
        </Form.Item>
        <Form.Item
          label="Total Asset Value"
          name={["family_details", "total_asset_value"]}
        >
          <Select placeholder="Please select your asset value">
            {ASSET_VALUE_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Asset Details"
          name={["family_details", "asset_details"]}
        >
          <TextArea
            maxLength={250}
            count={{
              show: true,
            }}
            rows={3}
            placeholder="Enter your asset details"
          />
        </Form.Item>
      </>
      <Title level={4}>Partner Preference</Title>
      <>
        <Form.Item label="Age Range" name={["partner_preference", "age_range"]}>
          <Slider range min={18} max={45} />
        </Form.Item>
        <Form.Item
          label="Marital Status"
          name={["partner_preference", "marital_status"]}
          required
        >
          <Select placeholder="Select your marital status">
            {MARITAL_STATUS_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Skin Tone" name={["partner_preference", "skin_tone"]}>
          <Group>
            {SKIN_COLOR_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item label="Education" name={["partner_preference", "education"]}>
          <Select
            showSearch
            mode="multiple"
            placeholder="Select your education"
          >
            {EDUCATION_LIST.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Occupation"
          name={["partner_preference", "occupation"]}
        >
          <Select showSearch placeholder="Select your occupation">
            {OCCUPATION_CATEGORY?.map((group) => (
              <OptGroup key={group} label={group}>
                {OCCUPATION_LIST[group].map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Horoscope Match"
          name={["partner_preference", "is_horoscope_match"]}
        >
          <Group>
            {PREFERENCE_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item label="Sevvai" name={["partner_preference", "is_sevvai"]}>
          <Group>
            {PREFERENCE_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Raaghu/Kedhu"
          name={["partner_preference", "is_ragu_kethu"]}
        >
          <Group>
            {PREFERENCE_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item label="Brothers" name={["partner_preference", "is_brother"]}>
          <Group>
            {PREFERENCE_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item label="Sisters" name={["partner_preference", "is_sisters"]}>
          <Group>
            {PREFERENCE_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Food Preference"
          name={["partner_preference", "food_preference"]}
        >
          <Group>
            {DIET_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="What I'm Looking For"
          name={["partner_preference", "description"]}
          rules={[
            {
              pattern: /^\D*$/,
              message: "Please enter only albhabets",
            },
          ]}
        >
          <TextArea
            maxLength={250}
            count={{
              show: true,
            }}
            rows={3}
            placeholder="Write your expectations"
          />
        </Form.Item>
      </>
      <Flex justify="center" gap="small">
        <Button onClick={handlePrevious}>Previous</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Register
        </Button>
      </Flex>
    </Form>
  );
};

export default Step3Form;
