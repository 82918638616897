import axios from "axios";
import { BASE_URL } from "../config";

const UseAxiosUpload = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

UseAxiosUpload.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    const accessToken = window.localStorage.accessToken;
    config.headers.Authorization = `jwt ${accessToken}`;
  }
  return config;
});

export default UseAxiosUpload;
