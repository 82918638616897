import { useState } from "react";
import { Button, Checkbox, Col, Flex, Form, Radio, Row, Select } from "antd";
import CHOICES from "../../constant/register-choices.json";

const { Option } = Select;
const { Group } = Radio;

const SearchFilterForm = (props) => {
  const { form2, handleSearchByFilters } = props;
  const [fromAge, setFromAge] = useState(null);
  const {
    MARITAL_STATUS_CHOICES,
    // KULAM_CHOICES,
    STAR_CHOICES,
    // PROFILE_CREATED_RECENTLY_IN,
    PREFERENCE_CHOICES,
    RASI_CHOICES,
  } = CHOICES;

  const initialValues = {
    age_from: "",
    age_to: "",
    marital_status: "",
    // kootam_to_avoid: [],
    star_list: [],
    rasi_list: [],
    // recent_created_in: "",
    is_ragu_kedhu: "",
    is_sevvai: "",
    is_profile_with_photo: false,
  };

  const handleFromAge = (value) => {
    setFromAge(value);
    form2.resetFields(["age_to"]);
  };

  const handleFormReset = () => {
    form2.resetFields();
    setFromAge(null);
  };

  return (
    <Form
      form={form2}
      autoComplete="off"
      initialValues={initialValues}
      className="search-filter-form"
      onFinish={handleSearchByFilters}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item label="Start Age" name="age_from">
            <Select
              showSearch
              onChange={handleFromAge}
              placeholder="Please select from age"
            >
              {Array.from({ length: 28 }, (_, index) => (
                <Option key={index} value={index + 18}>
                  {index + 18}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item label="End Age" name="age_to">
            <Select
              showSearch
              placeholder="Please select to age"
              disabled={!fromAge || fromAge === 45}
            >
              {Array.from({ length: 45 - fromAge }, (_, index) => (
                <Option key={index} value={index + 1 + fromAge}>
                  {index + 1 + fromAge}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item name="marital_status" label="Marital Status">
            <Select placeholder="Select your marital status">
              {MARITAL_STATUS_CHOICES?.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item label="Rasi" name="rasi_list">
            <Select mode="multiple" showSearch placeholder="Select your rasi">
              {RASI_CHOICES?.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item label="Natchathiram" name="star_list">
            <Select
              showSearch
              mode="multiple"
              placeholder="Select a nachathiram"
            >
              {STAR_CHOICES?.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item label="Kulam/Kootam to avoid" name="kootam_to_avoid">
            <Select showSearch mode="multiple" placeholder="Select your kulam">
              {KULAM_CHOICES?.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item label="Profile Created" name="recent_created_in">
            <Select placeholder="Select your profile created">
              {PROFILE_CREATED_RECENTLY_IN?.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item label="Ragu/Kedhu" name="is_ragu_kedhu">
            <Group>
              {PREFERENCE_CHOICES?.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item label="Sevvai" name="is_sevvai">
            <Group>
              {PREFERENCE_CHOICES?.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} xl={8}>
          <Form.Item
            label="Profile with Photo"
            valuePropName="checked"
            name="is_profile_with_photo"
            labelCol={12}
            wrapperCol={12}
          >
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
      <Flex justify="center" gap={8}>
        <Button type="primary" htmlType="submit">
          Search
        </Button>
        <Button danger type="primary" onClick={handleFormReset}>
          Clear
        </Button>
      </Flex>
    </Form>
  );
};

export default SearchFilterForm;
