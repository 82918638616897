import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginLayout, MainLayout, PolicyLayout } from "./layout";
import {
  Admin,
  Contact,
  Dashboard,
  Detail,
  Home,
  Login,
  MyPhotos,
  Payment,
  PrivacyPolicy,
  Profile,
  ProfileVerifications,
  Register,
  Report,
  Search,
  SearchById,
  TermsAndConditions,
} from "./views";
import data from "./mocks/data.json";
import "./app.scss";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* MainLayout contains Header, Main and Footer components */}
        <Route element={<MainLayout />}>
          <Route path="/search" element={<Search />} />
          <Route path="/search/:id" element={<SearchById />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact data={data} />}>
            <Route path=":id" element={<Detail />} />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/reportissue" element={<Report />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/profileVerification"
            element={<ProfileVerifications />}
          />
          <Route path="/myphotos" element={<MyPhotos />} />
        </Route>

        {/* LoginLayout contains Header and Main components */}
        <Route element={<LoginLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>

        {/* PolicyLayout contain only Main component */}
        <Route element={<PolicyLayout />}>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
