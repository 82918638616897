import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Empty,
  Flex,
  Image,
  Modal,
  Row,
  Typography,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { UseAuthAxios } from "../../utils";
import { startLoading, stopLoading } from "../../redux/reducers/loaderReducer";
import PhotoUpload from "./photo-upload";
import "./style.scss";

const { Title } = Typography;
const { confirm } = Modal;

const UserUploadLimit = {
  BASIC: 3,
  GOLD: 5,
  PLATINUM: 5,
  DIAMOND: 5,
};

const MyPhotos = () => {
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const membershipType = JSON.parse(
    window.localStorage.getItem("account_status")
  ).membership_type;
  const maxUploadLimit = UserUploadLimit[membershipType];

  const [isPhotoUpload, setIsPhotoUpload] = useState(false);
  const [userPhotos, setUserPhotos] = useState([]);

  const handleUploadModal = () => {
    setIsPhotoUpload((previous) => !previous);
  };

  const handleRemovePhoto = ({ media_id }) => {
    dispatch(startLoading());
    const url = "/delete_profile_media_files";
    const removePayload = { media_ids_to_delete: [media_id] };
    authAxios({ method: "delete", url, data: removePayload })
      .then(() => {
        const removedUserPhoto = userPhotos.filter(
          (data) => data.media_id !== media_id
        );
        setUserPhotos(removedUserPhoto);
        notification.success({ message: "Photo removed successfully" });
      })
      .catch((error) =>
        notification.error({
          message: error.response.data?.message || "Please try again later",
        })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handleRemoveConfirm = (data) => {
    confirm({
      centered: true,
      title: "Are you sure you want to delete this photo?",
      icon: <ExclamationCircleFilled />,
      onOk: () => handleRemovePhoto(data),
    });
  };

  const handleSetPrimary = (data, position) => {
    dispatch(startLoading());
    const url = "/set_primary_photo";
    const primaryPayload = {
      media_id: data.media_id,
    };

    authAxios
      .post(url, primaryPayload)
      .then((response) => {
        const cloneUserPhotos = [...userPhotos];
        cloneUserPhotos.splice(position, 1);
        cloneUserPhotos.unshift(data);
        setUserPhotos(cloneUserPhotos);
        notification.success({ message: response.data.message });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handleGetUserPhotos = (uploadResponse) => {
    dispatch(startLoading());
    const url = "/get_profile_media_details";

    authAxios
      .get(url)
      .then((response) => {
        setUserPhotos(response.data.data.image_details);
        if (uploadResponse) {
          notification.success({ message: uploadResponse.data.message });
        }
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  useEffect(() => {
    handleGetUserPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={[0, 16]} className="my-photo-container">
      <Col span={24} align="middle">
        <Title level={3}>My Photos</Title>
      </Col>
      <Col span={24} align="middle">
        <Button
          type="primary"
          size="large"
          onClick={handleUploadModal}
          disabled={maxUploadLimit === userPhotos.length}
        >
          Upload Photos
        </Button>
      </Col>
      <Col span={24}>
        <Row justify="center" gutter={[24, 24]}>
          {userPhotos.length ? (
            <Image.PreviewGroup>
              {userPhotos.map((data, index) => (
                <Col key={data.media_id}>
                  {index === 0 ? (
                    <Badge.Ribbon text="Primary" color="green">
                      <Image width={250} height={250} src={data.image_url} />
                    </Badge.Ribbon>
                  ) : (
                    <Image width={250} height={250} src={data.image_url} />
                  )}
                  <Flex justify="space-between" className="action-btn">
                    <Button
                      size="large"
                      type="primary"
                      disabled={index === 0}
                      onClick={() => handleSetPrimary(data, index)}
                    >
                      Set as Primary
                    </Button>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveConfirm(data)}
                      size="large"
                    >
                      Remove
                    </Button>
                  </Flex>
                </Col>
              ))}
            </Image.PreviewGroup>
          ) : isLoading ? (
            <h5>Loading...</h5>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No photos available. Please upload your photos."
            />
          )}
        </Row>
      </Col>
      <PhotoUpload
        isPhotoUpload={isPhotoUpload}
        handleGetUserPhotos={handleGetUserPhotos}
        handleUploadModal={handleUploadModal}
        maxUploadLimit={maxUploadLimit}
        userPhotos={userPhotos}
      />
    </Row>
  );
};

export default MyPhotos;
