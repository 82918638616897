import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Empty,
  Flex,
  Image,
  Modal,
  Row,
  Space,
  Tooltip,
  notification,
} from "antd";
import { HeartFilled, HeartOutlined, WarningOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { FaUserPlus, FaPhoneAlt, FaUserCheck } from "react-icons/fa";
import { FaUserXmark } from "react-icons/fa6";
import ProfileReport from "./profile-report";
import ProfileDetails from "./profile-details";
import { getProfileImageUrl } from "../helper";
import { UseAuthAxios } from "../../utils";
import { startLoading, stopLoading } from "../../redux/reducers/loaderReducer";
import "./style.scss";

const FAVOURITE_LOADER = 1;
const REQUEST_LOADER = 2;
const REPORT_LOADER = 3;

const SearchById = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();
  const globalLoading = useSelector((state) => state.loader.isLoading);

  const [isLoading, setIsLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isReport, setIsReport] = useState(false);
  const [profileData, setProfileData] = useState({});

  const { basic_details, other_details } = profileData;
  const connectionIcons = {
    "": <FaUserPlus className="send-request" />,
    Accepted: <FaUserCheck className="accepted" />,
    Rejected: <FaUserXmark className="rejected" />,
    Requested: <FaUserXmark className="cancel-request" />,
  };
  const connectionStatusTooltip = {
    "": "Send Request",
    Accepted: "Request Accepted",
    Rejected: "Request Rejected",
    Requested: "Cancel Request",
  };

  const connectionDisabled = {
    Accepted: true,
    Rejected: true,
  };

  const handleProfileData = (key, value, key2, value2) =>
    setProfileData((previous) => ({
      ...previous,
      other_details: {
        ...previous.other_details,
        [key]: value,
        ...(key2 && { [key2]: value2 }),
      },
    }));

  const handleFavourite = () => {
    setIsLoading(FAVOURITE_LOADER);
    const { profile_id } = basic_details;
    const isFavourite = other_details.is_favourite_profile;
    const url = isFavourite ? "/unshortlist_profile" : "/shortlist_profile";
    const method = isFavourite ? "delete" : "post";
    const favouritePayload = {
      profile_id,
    };
    authAxios({
      method,
      url,
      data: favouritePayload,
    })
      .then(() => {
        handleProfileData("is_favourite_profile", !isFavourite);
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(null));
  };

  const handleConnectionRequest = () => {
    setIsLoading(REQUEST_LOADER);
    const isCancelRequest =
      other_details.profile_connection_status === "Requested";
    const url = isCancelRequest
      ? "/delete_connection_request"
      : "/send_connection_request";
    const requestPayload = isCancelRequest
      ? {
          profile_connection_id: other_details.profile_connection_id,
          target_profile_id: basic_details.profile_id,
        }
      : {
          requesting_profile_id: basic_details.profile_id,
        };
    const method = isCancelRequest ? "delete" : "post";

    authAxios({
      method,
      url,
      data: requestPayload,
    })
      .then((response) => {
        const connectionValue = isCancelRequest ? "" : "Requested";
        const connectionId = isCancelRequest
          ? undefined
          : response.data.data.connection_request_id;
        handleProfileData(
          "profile_connection_status",
          connectionValue,
          "profile_connection_id",
          connectionId
        );
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(null));
  };

  const handleReportCancel = () => setIsReport(false);

  const handleReportSend = ({ report }) => {
    setIsLoading(REPORT_LOADER);
    const reportUrl = "/report_account";
    const reportPayload = {
      reported_profile_id: basic_details.profile_id,
      report,
    };
    authAxios
      .post(reportUrl, reportPayload)
      .then((response) => {
        setIsReport(false);
        notification.success({ message: response.data.message });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(null));
  };

  const handleContactDetails = () => {
    dispatch(startLoading());
    const { profile_id } = basic_details;
    const contactPayload = {
      profile_id,
    };
    const reportUrl = "/view_profile_contact_details";
    authAxios
      .post(reportUrl, contactPayload)
      .then((response) => {
        setProfileData(response.data.data);
        notification.success({ message: response.data.message });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handleConfirm = (isRequest) => {
    const isCancelRequest =
      other_details.profile_connection_status === "Requested" && isRequest;
    const title = isCancelRequest
      ? "Are you sure you want to cancel this connection request?"
      : "Are you sure you want to view the contact details?";
    const onCancel = isCancelRequest
      ? handleConnectionRequest
      : handleContactDetails;

    Modal.confirm({
      centered: true,
      className: "contact-confirm-modal",
      title,
      //<--- Swap button name for better UI. --->
      cancelText: "Yes",
      cancelButtonProps: {
        type: "primary",
      },
      okText: "No",
      okButtonProps: {
        danger: true,
      },
      // onCancel is a Yes button onCLick functionality.
      onCancel,
      width: "430px",
    });
  };

  const handleConnectionStatus = (request_status) => {
    setIsLoading(null);
    const method = "post";
    const url = "/respond_connection_request";
    const statusPayload = {
      accept_reject_connection_id: other_details.accept_reject_connection_id,
      request_status,
      target_profile_id: basic_details.profile_id,
    };
    authAxios({
      method,
      url,
      data: statusPayload,
    })
      .then(() => {
        handleProfileData(
          "profile_connection_status",
          request_status,
          "is_show_accept_reject_button",
          false
        );
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(null));
  };

  const buttonConfigs = [
    {
      key: "favourite",
      tooltip: other_details?.is_favourite_profile ? "Unfavorite" : "Favourite",
      loading: isLoading === FAVOURITE_LOADER,
      icon: other_details?.is_favourite_profile ? (
        <HeartFilled />
      ) : (
        <HeartOutlined />
      ),
      onClick: handleFavourite,
      disabled: false,
    },
    {
      key: "request",
      tooltip:
        connectionStatusTooltip[other_details?.profile_connection_status],
      loading: isLoading === REQUEST_LOADER,
      icon: connectionIcons[other_details?.profile_connection_status],
      onClick:
        other_details?.profile_connection_status === ""
          ? handleConnectionRequest
          : () => handleConfirm(true),
      disabled:
        connectionDisabled[other_details?.profile_connection_status] || false,
    },
    {
      key: "report",
      tooltip: "Report this Profile",
      icon: <WarningOutlined />,
      onClick: () => setIsReport(true),
      disabled: false,
    },
    {
      key: "contact",
      tooltip: "View Contact Details",
      icon: <FaPhoneAlt className="phone-icon" />,
      onClick: () => handleConfirm(false),
      disabled: other_details?.is_contact_viewed,
    },
  ];

  useEffect(() => {
    dispatch(startLoading());
    const profileUrl = `/search_profile_by_id/${id}`;
    authAxios
      .get(profileUrl)
      .then((response) => {
        setProfileData(response.data.data);
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(error.response.data.message);
          notification.error({ message: error.response.data.message });
        }
      })
      .finally(() => dispatch(stopLoading()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {profileData.basic_details ? (
        <>
          <Row
            justify="center"
            gutter={[0, 16]}
            className="profile-pic-container"
          >
            <Col span={24} align="middle">
              <Image.PreviewGroup items={other_details.profile_photo_list}>
                <Image
                  src={getProfileImageUrl(profileData, true)}
                  preview={!!other_details.profile_photo_list.length}
                />
              </Image.PreviewGroup>
            </Col>
            {!other_details.is_hide_action_buttons && (
              <>
                <Col span={24} align="middle">
                  <Space size="middle">
                    {buttonConfigs.map((config) => (
                      <Tooltip key={config.key} title={config.tooltip}>
                        <Button
                          type="text"
                          shape="circle"
                          loading={config.loading}
                          icon={config.icon}
                          onClick={config.onClick}
                          disabled={config.disabled}
                        />
                      </Tooltip>
                    ))}
                  </Space>
                </Col>
              </>
            )}
            {other_details.is_show_accept_reject_button && (
              <Flex gap="small">
                <Button
                  onClick={() => handleConnectionStatus("Accepted")}
                  type="primary"
                >
                  Accept
                </Button>
                <Button
                  danger
                  onClick={() => handleConnectionStatus("Rejected")}
                  type="primary"
                >
                  Reject
                </Button>
              </Flex>
            )}
            <Col span={24} align="middle">
              <h3 className="profile-title">
                {profileData.basic_details.name} (
                {profileData.basic_details.profile_id})
              </h3>
            </Col>
            {/* <Col className="view-count-container">
              {other_details.visited_profiles_count} user viewed this profile
            </Col> */}
            <Col className="last-active">
              {other_details.last_active_datetime
                ? `Last Active at: ${other_details.last_active_datetime}`
                : null}
            </Col>
          </Row>
          <ProfileDetails profileData={profileData} />
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            globalLoading
              ? "Loading data, Please wait..."
              : `No profile match. ${errorMessage}`
          }
        />
      )}
      <ProfileReport
        isReport={isReport}
        isLoading={isLoading}
        handleReportSend={handleReportSend}
        handleReportCancel={handleReportCancel}
      />
    </>
  );
};

export default SearchById;
