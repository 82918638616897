import React, { useState } from "react";
import { Button, Flex, Modal, Upload, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UseAxiosUpload } from "../../utils";

const { Dragger } = Upload;

const PhotoUpload = (properties) => {
  const {
    isPhotoUpload,
    handleGetUserPhotos,
    handleUploadModal,
    maxUploadLimit,
    userPhotos,
  } = properties;

  const [isUploading, setIsUploading] = useState(false);
  const [photoList, setPhotoList] = useState([]);

  const handleFileUpload = (event) => {
    const { file, fileList } = event;
    const isImageType =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (isImageType) {
      setPhotoList(fileList);
    } else {
      notification.error({ message: "Upload JPG/PNG" });
    }
  };

  const handlePhotoSave = () => {
    setIsUploading(true);
    const formData = new FormData();
    photoList.forEach((file) => {
      formData.append("files", file.originFileObj);
    });
    const url = "/upload_file";
    UseAxiosUpload.post(url, formData)
      .then((response) => {
        handleGetUserPhotos(response);
        handleCancel();
      })
      .catch((error) =>
        notification.error({
          message: error.response.data.message || "Please try again later.",
        })
      )
      .finally(() => setIsUploading(false));
  };

  const handleCancel = () => {
    setPhotoList([]);
    handleUploadModal();
  };

  return (
    <>
      <Modal
        centered
        className="photo-upload-modal"
        closable={false}
        destroyOnClose
        footer={
          <Flex gap={8} justify="center">
            <Button
              disabled={!photoList.length}
              loading={isUploading}
              onClick={handlePhotoSave}
              size="large"
              type="primary"
            >
              Save
            </Button>
            {!isUploading && (
              <Button danger onClick={handleCancel} size="large" type="primary">
                Cancel
              </Button>
            )}
          </Flex>
        }
        maskClosable={false}
        open={isPhotoUpload}
        onCancel={handleUploadModal}
        title="Upload Photos"
      >
        <Dragger
          beforeUpload={() => false}
          className="photo-upload"
          listType="picture"
          maxCount={maxUploadLimit - userPhotos.length}
          multiple
          fileList={photoList}
          onChange={handleFileUpload}
        >
          <UploadOutlined />
          <span>Upload Images (JPEG / JPG / PNG)</span>
          <span>(Max: {maxUploadLimit - userPhotos.length})</span>
        </Dragger>
      </Modal>
    </>
  );
};

export default PhotoUpload;
