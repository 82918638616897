import { Table, Typography } from "antd";
import "./style.scss";

const Payment = () => {
  const columns = [
    {
      dataIndex: "feature",
      key: "feature",
      title: "FEATURE",
      align: "center",
      fixed: "left",
    },
    {
      dataIndex: "BASIC",
      key: "BASIC",
      title: "BASIC",
      align: "center",
    },
    {
      dataIndex: "GOLD",
      key: "GOLD",
      title: "GOLD",
      align: "center",
    },
    {
      dataIndex: "DIAMOND",
      key: "DIAMOND",
      title: "DIAMOND",
      align: "center",
    },
    {
      dataIndex: "PLATINUM",
      key: "PLATINUM",
      title: "PLATINUM",
      align: "center",
    },
  ];

  const dataSource = [
    {
      BASIC: "18 Months (Profile Auto Delete)",
      DIAMOND: "6 Months (180 Days)",
      GOLD: "3 Months (90 Days)",
      PLATINUM: "12 Months (365 Days)",
      feature: "Membership Validity",
      key: "Membership Validity",
    },
    {
      BASIC: "5",
      DIAMOND: "50",
      GOLD: "30",
      PLATINUM: "Unlimited",
      feature: "Max Profile Shortlist",
      key: "Max Profile Shortlist",
    },
    {
      BASIC: "3",
      DIAMOND: "5",
      GOLD: "5",
      PLATINUM: "5",
      feature: "Max Photo Upload",
      key: "Max Photo Upload",
    },
    {
      BASIC: <span className="profile-view-icon">❌</span>,
      DIAMOND: <span className="profile-view-icon">✅</span>,
      GOLD: <span className="profile-view-icon">✅</span>,
      PLATINUM: <span className="profile-view-icon">✅</span>,
      feature: "Full Profile View",
      key: "Full Profile View",
    },
    {
      BASIC: "0",
      DIAMOND: "50",
      GOLD: "30",
      PLATINUM: "Unlimited",
      feature: "Contact Number Views",
      key: "Contact Number Views",
    },
    {
      BASIC: "Only last 5 profiles",
      DIAMOND: "Unlimited",
      GOLD: "Unlimited",
      PLATINUM: "Unlimited",
      feature: "Profile Viewers List",
      key: "Profile Viewers List",
    },
    {
      BASIC: <span className="price-container">Free</span>,
      DIAMOND: <span className="price-container">2500₹</span>,
      GOLD: <span className="price-container">1500₹</span>,
      PLATINUM: <span className="price-container">4000₹</span>,
      feature: "Price",
      key: "Price",
    },
  ];

  return (
    <>
      <Table
        bordered
        className="payment-table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          x: 720,
        }}
      />
      <br />
      <Typography.Title level={5} type="danger">
        *Note : Membership features and payment details will vary time to time.
      </Typography.Title>
    </>
  );
};

export default Payment;
