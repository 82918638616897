import axios from "axios";
import { useNavigate } from "react-router-dom";
import UseAxios from "./useAxios";
import { BASE_URL } from "../config";

const useAuthAxios = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use((config) => {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `jwt ${accessToken}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        const payload = { refresh: refreshToken };

        try {
          const accessResponse = await UseAxios.post(
            "get_refresh_token",
            payload
          );
          const newAccessToken = accessResponse.data.access || "";
          localStorage.setItem("accessToken", newAccessToken);

          error.config.headers["Authorization"] = `jwt ${newAccessToken}`;
          return await axiosInstance(error.config);
        } catch (error) {
          alert("Your session has expired. Please log in again.");
          navigate("/login");
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAuthAxios;
