import { useState } from "react";
import { Button, Form, Input } from "antd";
import { toast } from "react-toastify";
import { encryptData, UseAuthAxios } from "../../utils";
import { getProfileImageUrl } from "../helper";
import "./style.scss";

const Profile = () => {
  const authAxios = UseAuthAxios();
  const userInfo = JSON.parse(localStorage.getItem("userDetails"));
  const [accStatus, setaccStatus] = useState(
    JSON.parse(localStorage.getItem("account_status"))
  );

  const [open, setOpen] = useState(false);
  const { Item } = Form;

  const onFinish = (values) => {
    changePasswordCall(values);
  };

  const changePasswordCall = (payload) => {
    let url = "change_password";
    let confirmPass = encryptData(payload.confirm_new_password);
    let newPass = encryptData(payload.new_password);
    let oldPass = encryptData(payload.old_password);

    let framePayload = {
      confirm_new_password: confirmPass,
      new_password: newPass,
      old_password: oldPass,
    };

    authAxios.post(url, framePayload).then(
      (res) => {
        handleClose();
        toast.success(res.data.message);
      },
      (err) => {
        handleClose();
        toast.error(err.response.data.message || "Failed");
      }
    );
  };

  const checkStatus = (data) => {
    if (data) {
      accountStatus(data);
    }
  };

  const accountStatus = (payload) => {
    let url = "set_user_account_status";
    let framePayload = {
      account_status: payload,
    };

    authAxios.post(url, framePayload).then(
      (res) => {
        localStorage.setItem("account_status", JSON.stringify(res?.data.data));
        setaccStatus(JSON.parse(localStorage.getItem("account_status")));
        toast.success(res.data.message);
      },
      (err) => {
        toast.error(err.response.data.message || "Failed");
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {!open ? (
        <div id="algn">
          <div id="card">
            <div id="upper-bg">
              <img
                src={getProfileImageUrl(userInfo)}
                alt="profile-pic"
                className="profile-pic"
              />
            </div>
            <div id="lower-bg">
              <div className="text">
                <p className="name">
                  {userInfo?.user_name ? userInfo?.user_name : ""}{" "}
                  {userInfo?.user_id ? "(" + userInfo?.user_id + ")" : ""}
                </p>
              </div>

              <div id="btn">
                <button className="msg" onClick={handleOpen}>
                  Change Password
                </button>
              </div>

              <div id="btn">
                {!accStatus?.account_status ? (
                  <button
                    className="sec"
                    onClick={() => checkStatus("activate")}
                  >
                    Active Profile
                  </button>
                ) : (
                  <button
                    className="sec"
                    onClick={() => checkStatus("deactivate")}
                  >
                    Deactive Profile
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="algn">
          <div className="d-flex col-sm-12 col-md-12 col-lg-6 box m-3 justify-content-center">
            <Form
              className="login-form m-5 w-100"
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Item
                label="Old Password"
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your old password!",
                  },
                ]}
              >
                <Input />
              </Item>

              <Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your new password!",
                  },
                ]}
              >
                <Input.Password />
              </Item>

              <Item
                label="Confirm New Password"
                name="confirm_new_password"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Confirm new password!",
                  },
                ]}
              >
                <Input.Password />
              </Item>

              <Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  type="primary"
                  className="primary-color w-40 m-1"
                  htmlType="submit"
                >
                  Submit
                </Button>

                <Button
                  type="primary"
                  style={{ background: "grey", borderColor: "black" }}
                  className="w-40 m-1"
                  onClick={handleClose}
                >
                  cancel
                </Button>
              </Item>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
