import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Menu,
  Typography,
  Avatar,
  Button,
  Popover,
  Tooltip,
  Flex,
  Image,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  MenuOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../utils";
import { startLoading, stopLoading } from "../../redux/reducers/loaderReducer";
import webicon from "../../image/vkvmlogo.png";
import "./style.scss";

const { Title } = Typography;

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();

  const [userDetails, setUserDetails] = useState();
  const [isMenuPopover, setIsMenuPopver] = useState(false);
  const [subMenu, setSubMenu] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);

  const handleMenuPopOver = (value) => {
    setSubMenu([]);
    setIsMenuPopver(value);
  };

  const handleSubMenu = (key) => {
    setSubMenu(key);
  };

  const handleLogout = () => {
    dispatch(startLoading());
    const url = "logout";
    const refresh_token = localStorage.getItem("refreshToken");
    const payload = {
      refresh_token,
    };

    authAxios
      .post(url, payload)
      .then(() => navigate("/login"))
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  const handleMenuSelect = ({ key }, isMobile) => {
    if (isMobile) {
      handleMenuPopOver(false);
    }
    if (key === "/logout") {
      handleLogout();
    } else {
      navigate(key);
    }
  };

  const items = useMemo(
    () => [
      isAdmin
        ? {
            key: "/admin",
            label: (
              // <img src={admin} className="w-25" alt="" />
              <Tooltip placement="left" title="admin">
                {" "}
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserSwitchOutlined />}
                />
              </Tooltip>
            ),
            // <p className="admin">Admin</p>,
            children: [
              { key: "/profileVerification", label: "Profile Verification" },
              // { key: "/memberShipUpdate", label: "MemberShip Update" },
            ],
          }
        : null,
      { key: "/", label: "Home" },
      isLoggedIn
        ? {
            key: "/dashboard",
            label: isLoggedIn ? "Dashboard" : null,
          }
        : { key: "/register", label: "Register" },
      isAdmin && { key: "/register", label: "Register" },
      isLoggedIn ? { key: "/search", label: "Search" } : null,
      { key: "/payment", label: "Payment" },
      { key: "/contact", label: "Contact" },
      {
        key: "/login",
        label: isLoggedIn ? (
          <Avatar
            style={{ backgroundColor: "#87d068" }}
            icon={<UserOutlined />}
          />
        ) : (
          "Login"
        ),
        children: isLoggedIn
          ? [
              { key: "/profile", label: "Profile" },
              { key: "/reportissue", label: "Report Issue" },
              { key: "/myphotos", label: "My Photos" },
              {
                key: "",
                label: "View My Profile",
                onClick: () => {
                  handleSearchByIDnavigate();
                },
              },
              { key: "/logout", label: "Logout" },
            ]
          : null,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoggedIn]
  );

  const handleSearchByIDnavigate = () => {
    if (isLoggedIn) {
      window.open(`/search/${userDetails?.user_id}`, "_blank");
    } else {
      alert("Your session has expired. Please log in again.");
      navigate("/login");
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userInfo ? userInfo : "");
    const token = localStorage.getItem("accessToken");
    setIsLoggedIn(token ? true : false);
    const isadmin = userInfo?.profile_details?.is_admin_user;
    setisAdmin(isadmin ? true : false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [isAdmin]);

  return (
    <Row align="middle" justify="space-between" className="header-container">
      <Flex
        align="center"
        className="logo-container"
        gap={8}
        onClick={() => handleMenuSelect({ key: "/" })}
      >
        <Image height={36} preview={false} src={webicon} />
        <Title level={3}>
          NKVG Matrimony <sup>*Trial</sup>
        </Title>
      </Flex>
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={{
          span: 16,
          flex: "510px",
        }}
      >
        <Menu
          items={items}
          mode="horizontal"
          onClick={handleMenuSelect}
          className="web-menu"
          defaultSelectedKeys={[window.location.pathname]}
        />
      </Col>
      <Col xs={2} sm={2} md={1} lg={0} xl={0} xxl={0}>
        <Popover
          arrow={false}
          trigger="click"
          open={isMenuPopover}
          placement="bottomRight"
          overlayClassName="mobile-menu"
          onOpenChange={handleMenuPopOver}
          content={
            <Menu
              mode="inline"
              items={items}
              openKeys={subMenu}
              onOpenChange={handleSubMenu}
              onClick={(event) => handleMenuSelect(event, true)}
              defaultSelectedKeys={[window.location.pathname]}
            />
          }
        >
          <Button type="text" icon={<MenuOutlined />} className="menu-icon" />
        </Popover>
      </Col>
    </Row>
  );
};

export default Header;
